import React from "react"

import "./faq-question.scss"

const FaqQuestion = ({question, answer}) => (

    <details className="question">
        <summary><h4>{question}</h4></summary>
        <p>{answer}</p>
    </details>

)

export default FaqQuestion