import React, { useEffect, createRef } from "react"

import lottie from "lottie-web"


const LottiePlayer = ({animationData}) => {

    let animationContainer = createRef()

    useEffect(() => {
        let anim = lottie.loadAnimation({
          container: animationContainer.current,
          renderer: "svg",
          loop: true,
          autoplay: true,
          animationData: animationData
        });
        return () => anim.destroy()
    }, []);

    return (
        <div ref={animationContainer} className="animation"></div>
    )

}

export default LottiePlayer