import React from 'react'

import "./section-header.scss"

const SectionHeader = ({title, description}) => (
    <header className="section-header">
        <h2> {title} </h2>
        <p> {description} </p>
    </header>
)

export default SectionHeader

