import React, { useState } from "react"

import emailjs from 'emailjs-com'

import loader from '../../images/loader.svg'
import "./contact-form.scss"

const ContactForm = () => {

    let quotationCopy = "Request pricing"

    let [formData, setFormData] = useState({
        name: '',
        email: '',
        company: '',
        subject: quotationCopy,
        message: '',
    })
    let [isValidated, setIsValidated] = useState(false)
    let [isLoading, setIsLoading] = useState(false)
    let [msgSent, setMsgSent] = useState(false)
    let [gotError, setGotError] = useState(false)

    function handleSubmit(e) {
        e.preventDefault();

        const {name, email, company, subject, message} = formData

        let templateParams = {
            client_name: name,
            client_email: email,
            company,
            subject, 
            message
        }

        setIsLoading(true)
        
        emailjs.send(
            'service_es5joar',
            'template_6rpp9z9',
             templateParams,
            'user_r7YH5H2nXQgyABPbf9G9u'
        ).then((result) => {
            setMsgSent(true)
            setIsLoading(false)

            triggerConversionTracking()

            resetForm()
        }, (error) => {
            setGotError(true)
            setIsLoading(false)
        });
       
    
    }
    function handleChange(e) {
        e.preventDefault();

        const name = e.target.name
        const value = e.target.value
        setFormData({
                ...formData,
                [name]: value
            }
        )
        validateForm()

    }
    function resetForm(){
        setFormData({
            name: '',
            email: '',
            company: '',
            subject: quotationCopy,
            message: '',
        })
    }
    function validateForm(){
        const {name, email, message} = formData

        if(name && email && message){
            setIsValidated(true)
        } else {
            setIsValidated(false)
        }
    }

    function triggerConversionTracking(){
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            'event': 'formSubmission',
            'category': 'Form sent',
            'action': 'formSubmission',
            'label': 'Contact form submission'
        })
    }


    return (
        <form onSubmit={handleSubmit}>
            <div className="input-group">
                <label htmlFor="name">Name</label>
                <input type="text" name="name" value={formData.name} onChange={handleChange}/>
            </div>
            <div className="input-group">
                <label htmlFor="email">E-mail</label>
                <input type="email" name="email" value={formData.email} onChange={handleChange}/>
            </div>
            <div className="input-group">
                <label htmlFor="company">Company (Optional)</label>
                <input type="text" name="company" value={formData.company} onChange={handleChange}/>
            </div>
            
            <div className="input-group">
                <label htmlFor="subject">Subject</label>
                <select name="subject" id="subject" value={formData.jubject} onChange={handleChange}>
                <option value="Quotation">{quotationCopy}</option>
                <option value="Contact">Contact</option>
                </select>
            </div>
            <div className="input-group full">
                <label htmlFor="message">Message</label>
                <textarea name="message" id="message" cols="30" rows="4" value={formData.message} onChange={handleChange} />
            </div>
            {
                msgSent && 
                <p className="success">Thanks for contacting us! <br/>We typically reply in few hours.</p>
            }
            {
                gotError &&
                <p className="error">We've got some error, please try again.</p>
            }
            {
                !msgSent && !isLoading &&
                <input type="submit" value="Send" 
                    disabled={!isValidated} 
                    title={ isValidated ? "You got it!" : "First things first, please!" }
                />
            }
            {
                isLoading &&
                <div className="loader-container">  
                    <img className="spin" src={loader} alt="loader"/>
                </div>
            }
        </form>
    )

}


export default ContactForm