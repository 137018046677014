import React from 'react'

import LottiePlayer from "../lottie/lottie-player"

import './card.scss'

const Card = ({title, description, animationData}) => (
    <div className="card">
        <div className="animation-container">
            <LottiePlayer animationData={animationData}/>
        </div>
        <div className="card-content">
            <h3>{title}</h3>
            <p>{description}</p>
        </div>
    </div>
)

export default Card
